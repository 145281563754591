import acpAnytimeAlertsTermsandConditionTemplate from './templates/acp-anytime-alerts-terms-condition.html'
// @ngInject
function AcpAnytimeAlertsSignUpComponentCtrl(
  $scope,
  $mdDialog,
  $window,
  nsPermissions,
  acpTermsModel,
  acpAlertsSignUpModel,
  acpBrandingClient,
  acpCoreDispatcher,
  acpDeviceDetectionService
) {
  function init() {
    $scope.updatedTermsUrl = '';
    $scope.busy = false;
    $scope.isInterstitialTermsDisplay = false;

    $scope.fieldErrors = {};
    $scope.serverErrors = {};
    $scope.relevantServerErrors = acpAlertsSignUpModel.possibleErrors;
    $scope.hasErrors = false;
    $scope.branding = acpBrandingClient.get();

    acpTermsModel.getAnytimeAlertsTermsUrlV2().then(function(response) {
      $scope.updatedTermsUrl = response;
    });
  }

  function acceptTerms() {
    return nsPermissions.requestPermission('isPushNotificationEnabled')
            .then(perms => acpTermsModel.acceptTerms(`alerts${perms ? '_v2' : ''}`));
  }

  function enroll() {
    var aa = $scope.aa;
    var isPhone = $scope.aa.phone !== '';

    var data = {
      address_type: isPhone ? 'phone_number' : 'email',
      address: isPhone ? aa.phone : aa.email,
      per_transaction: true
    };

    acpAlertsSignUpModel.subscribe(data);
  }

  function enrollSuccess() {
    $scope.busy = false;
  }

  function enrollError(err) {
    $scope.busy = false;

    $scope.serverErrors = err._server_errors;
    $scope.fieldErrors = err._field_errors;
    $scope.hasErrors = true;
  }

  $scope.signup = function(form) {
    if ($scope.requirementsMet(form)) {
      acpCoreDispatcher.scrollTo.top.emit();

      $scope.hasErrors = false;
      $scope.busy = true;
      acceptTerms().then(enroll);
    }
  };

  $scope.aa = {
    email: '',
    phone: '',
    accepted: false
  };

  function isPhoneValid(form) {
    return (
      form.aaPhone &&
      form.aaPhone.$modelValue !== '' &&
      form.aaPhone.$modelValue !== null &&
      form.aaPhone.$valid
    );
  }

  function isEmailValid(form) {
    return (
      form.aaEmail &&
      form.aaEmail.$modelValue !== '' &&
      form.aaEmail.$modelValue !== null &&
      form.aaEmail.$valid
    );
  }

  $scope.requirementsMet = function(form) {
    return (
      form &&
      (isPhoneValid(form) || isEmailValid(form)) &&
      form.aaAccepted.$modelValue === true
    );
  };

  $scope.hasMobileNumber = function(form) {
    return (
      form && isPhoneValid(form) &&
      form.aaAccepted.$modelValue === true
    );
  }

  $scope.showAppCenterBadge = function() {
    return ($scope.branding.ios_app_url && !acpDeviceDetectionService.isAndroid());
  }

  $scope.showPlayStoreBadge = function() {
    return ($scope.branding.android_app_url && !acpDeviceDetectionService.isiOS());
  }

  $scope.displayInterstitialTerms = function() {
    $scope.isInterstitialTermsDisplay = !$scope.isInterstitialTermsDisplay;
  };

  $scope.showTermsAndConditionDialog = function() {
    $mdDialog.show({
      parent: $window.document.body,
      template: acpAnytimeAlertsTermsandConditionTemplate,
      fullscreen: true,
      skipHide: true,
      controller: /* @ngInject */ function() {
        var vm = this;
        vm.termsUrl = function() {
          return $scope.updatedTermsUrl;
        },
        vm.dismissDialog = function() {
          $mdDialog.hide();
        };
      },
      controllerAs: 'vm',
    });
  };

  $scope.$onValue(acpCoreDispatcher.alerts.subscribed.success, enrollSuccess);
  $scope.$onValue(acpCoreDispatcher.alerts.subscribed.failed, enrollError);
  $scope.$onValue(acpCoreDispatcher.terms.accepted.error, enrollError);

  init();
}

export default AcpAnytimeAlertsSignUpComponentCtrl;
