// @ngInject
function AcpAnytimeAlertsActivationComponentCtrl(
  $location,
  $scope,
  acpAnalytics,
  acpAlertsSignUpModel,
  acpCoreDispatcher,
  acpTermsModel
) {
  var submitting = false;

  $scope.$onValue(
    acpCoreDispatcher.activation.featureEnrollment.anytimealerts.complete,
    enrollSuccess
  );
  $scope.$onValue(
    acpCoreDispatcher.activation.featureEnrollment.anytimealerts.failed,
    enrollFailed
  );
  $scope.$onValue(acpCoreDispatcher.terms.accepted.error, enrollFailed);

  function init() {
    trackAA('viewed');

    $scope.busy = false;
    $scope.possibleErrors = acpAlertsSignUpModel.possibleErrors;
    $scope.hasErrors = false;

    acpTermsModel.getAnytimeAlertsTermsUrl().then(function(response) {
      $scope.termsUrl = response;
    });
  }

  function trackAA(action) {
    if ($location.path() === '/activate/additional-card') {
      acpAnalytics.sendEvent(
        'feature_enrollments',
        action,
        'activation_postlogin_aa'
      );
    } else {
      acpAnalytics.sendEvent(
        'feature_enrollments',
        action,
        'activation_prelogin_aa'
      );
    }
  }

  function enroll() {
    var aa = $scope.anytimeAlerts;

    var data = {
      address_type: 'phone_number',
      address: aa.address,
      per_transaction: true
    };

    acpAlertsSignUpModel.subscribe(data);
  }

  function enrollSuccess() {
    $scope.busy = false;
  }

  function enrollFailed(err) {
    $scope.busy = false;

    $scope.serverErrors = err._server_errors;
    $scope.fieldErrors = err._field_errors;
    $scope.hasErrors = true;
  }

  $scope.isInvalid = function() {
    return (
      $scope.anytimeAlerts.$invalid ||
      !$scope.anytimeAlerts.enabled ||
      !$scope.anytimeAlerts.agree
    );
  };

  $scope.isSubmitting = function() {
    return submitting;
  };

  $scope.signup = function() {
    if ($scope.isInvalid() || $scope.isSubmitting()) {
      return;
    }

    acpCoreDispatcher.scrollTo.top.emit();
    submitting = true;
    trackAA('opted_in');

    $scope.fieldErrors = null;
    $scope.serverErrors = null;
    $scope.hasErrors = false;
    $scope.busy = true;

    acpTermsModel.acceptTerms('alerts').then(enroll);
  };

  $scope.skip = function() {
    trackAA('opted_out');
    acpCoreDispatcher.activation.featureEnrollment.anytimealerts.complete.emit();
  };

  init();
}

export default AcpAnytimeAlertsActivationComponentCtrl;
