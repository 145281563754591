import acpAnytimeAlertsActivationTemplate from './templates/acp-anytime-alerts-activation.html';
// @ngInject
function acpAnytimeAlertsActivationDirective() {
  return {
    controller: 'AcpAnytimeAlertsActivationComponentCtrl',
    restrict: 'E',
    scope: {},
    template: acpAnytimeAlertsActivationTemplate
  };
}

export default acpAnytimeAlertsActivationDirective;
