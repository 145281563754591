import anytimeAlertsSignUpContent from './anytime-alerts-sign-up-content.yml';
import acpAnytimeAlertsActivationCtrl from './acp-anytime-alerts-activation-ctrl';
import acpAnytimeAlertsSignUpCtrl from './acp-anytime-alerts-sign-up-ctrl';
import acpAnytimeAlertsInterstitialDirective from './acp-anytime-alerts-interstitial-directive';
import acpAnytimeAlertsRedesignInterstitialDirective from './acp-anytime-alerts-redesign-interstitial-directive';
import acpAnytimeAlertsSignUpDirective from './acp-anytime-alerts-sign-up-directive';
import acpAnytimeAlertsActivationDirective from './acp-anytime-alerts-activation-directive';
import acpComponentAnytimeAlertsDomain from 'components/anytime-alerts-domain';
import acpCore from 'core';
import ng from 'angular';
import './styles/${theme}/core.scss';

/**
 * @acpdoc component
 * @ngdoc module
 * @name acp.component.anytime-alerts
 *
 * @requires acp.core
 *
 * @description
 *
 * @example
 * ```html
 * <acp-anytime-alerts></acp-anytime-alerts>
 * ```
 *
 * ```js
 * angular.module('acp.component.anytime-alerts.example', [
 *   require('acp.component.anytime-alerts').name
 * ]);
 * ```
 */
var component = ng
  .module('acp.component.anytime-alerts-sign-up', [
    acpCore.name,
    acpComponentAnytimeAlertsDomain.name
  ])
  .directive('acpAnytimeAlertsActivation', acpAnytimeAlertsActivationDirective)
  .directive('acpAnytimeAlertsSignUp', acpAnytimeAlertsSignUpDirective)
  .directive(
    'acpAnytimeAlertsInterstitial',
    acpAnytimeAlertsInterstitialDirective
  )
  .directive(
    'acpAnytimeAlertsRedesignInterstitial',
    acpAnytimeAlertsRedesignInterstitialDirective
  )
  .controller('AcpAnytimeAlertsSignUpComponentCtrl', acpAnytimeAlertsSignUpCtrl)
  .controller(
    'AcpAnytimeAlertsActivationComponentCtrl',
    acpAnytimeAlertsActivationCtrl
  )
  .run(
    /*@ngInject*/ function(contentSectionCache) {
      contentSectionCache.put(
        'components/anytime-alerts-sign-up',
        anytimeAlertsSignUpContent
      );
    }
  );

export default component;
