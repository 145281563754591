import acpAnytimeAlertsInterstitialTemplate from './templates/acp-anytime-alerts-interstitial.html';
// @ngInject
function acpAnytimeAlertsInterstitial() {
  return {
    controller: 'AcpAnytimeAlertsSignUpComponentCtrl',
    restrict: 'E',
    scope: {
      acpInterstitialCtrl: '=ctrl'
    },
    template: acpAnytimeAlertsInterstitialTemplate
  };
}

export default acpAnytimeAlertsInterstitial;
