import acpAnytimeAlertsRedesignInterstitialTemplate from './templates/acp-anytime-alerts-redesign-interstitial.html';
// @ngInject
function acpAnytimeAlertsRedesignInterstitial() {
  return {
    controller: 'AcpAnytimeAlertsSignUpComponentCtrl',
    restrict: 'E',
    scope: {
      acpInterstitialCtrl: '=ctrl'
    },
    template: acpAnytimeAlertsRedesignInterstitialTemplate
  };
}

export default acpAnytimeAlertsRedesignInterstitial;
