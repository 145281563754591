import acpAnytimeAlertsSignUpTemplate from './templates/acp-anytime-alerts-sign-up.html';
// @ngInject
function acpAnytimeAlertsSignUpDirective() {
  return {
    controller: 'AcpAnytimeAlertsSignUpComponentCtrl',
    restrict: 'E',
    scope: {},
    template: acpAnytimeAlertsSignUpTemplate
  };
}

export default acpAnytimeAlertsSignUpDirective;
